import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import TableOfContents from '../components/TableOfContents';
import Content, { HTMLContent } from '../components/Content';
import { randomId } from '../utils/index';
import config from '../config';

const Grid = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
  display: 'grid',
  gridTemplateColumns: 'repeat(12,1fr)',
  gridGap: '0 32px',
  maxWidth: '1120px',
  gridTemplateRows: 'max-content',
}));

const Sidebar = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    gridColumn: '1 / -1',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '64px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: '32px',
  },
  gridColumn: '1 / 4',
}));

const Main = styled('div')(() => ({
  gridColumn: '4 / -1',
}));

const Author = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    order: 2,
    marginTop: 24,
  },
  display: 'flex',
  marginTop: theme.spacing(2),
  '& .authorImage': {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
}));

const AuthorInfo = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: 'inherit',
  fontWeight: 500,
}));

const AuthorName = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const AuthorDescription = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'normal',
}));

const TableOfContentsNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginTop: 0,
    top: 0,
    width: 'calc(50% - 32px)',
    position: 'relative',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    order: 1,
  },
  marginTop: '64px',
  top: '32px',
  position: 'sticky',
}));

const Tags = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

const Navigation = styled('nav')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / -1',
    gridTemplateColumns: 'auto 1fr',
    gridGap: 32,
    alignItems: 'stretch',
  },
  display: 'grid',
  gridTemplateColumns: 'repeat(8,1fr)',
  gridGap: '0 32px',
  alignItems: 'start',
  gridColumn: '5 / -1',
  marginTop: theme.spacing(2),
  '& .navigationLink': {
    '&:hover': {
      background: '#F5F5F5',
      textDecoration: 'none',
      color: '#3273dc',
    },
  },
  '& .navigationPrev': {
    padding: theme.spacing(3),
    background: 'inherit',
    width: '100%',
    gridColumn: '1 / span 4',
    marginLeft: theme.spacing(-3),
  },
  '& .navigationNext': {
    padding: theme.spacing(3),
    background: 'inherit',
    width: '100%',
    gridColumn: '5 / span 4',
  },
  '& .navigationSubtext': {
    color: theme.palette.text.secondary,
    marginBottom: 4,
  },
  '& .navigationText': {
    fontSize: 20,
    fontWeight: 700,
  },
}));

const TocButton = styled('button')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    fontSize: 'inherit',
    border: 0,
    display: 'flex',
    color: '#282828',
    background: 'inherit',
    borderBottom: '3px solid #282828',
    cursor: 'pointer',
    padding: '11px 0',
    width: '100%',
    textAlign: 'left',
    outline: 0,
  },
  display: 'none',
}));

const TocItems = styled('div')(({ theme, isDropdownOpen }) => ({
  [theme.breakpoints.down('lg')]: {
    display: isDropdownOpen ? 'block' : 'none',
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    minWidth: 280,
    marginTop: '-3px',
    background: '#fff',
    padding: '24px',
    border: '3px solid #282828',
    right: 0,
  },
}));

const sxTocButtonIcon = (theme) => ({
  marginLeft: theme.spacing(1),
  width: 20,
  height: 20,
});

// eslint-disable-next-line
export const BlogPostTemplate = ({
  helmet,
  location,
  post,
  isPreview,
  authorImage,
  content,
  contentComponent,
  description,
  tags,
  title,
  tableOfContents,
  disableTableOfContents,
  author,
  previous,
  next,
}) => {
  const toc = !disableTableOfContents && tableOfContents && tableOfContents.items;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const PostContent = contentComponent || Content;
  return (
    <React.Fragment>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
        className={'content'}
      >
        {helmet || ''}
        <Grid>
          <Sidebar>
            <Author>
              {
                authorImage && (
                  <Link
                    to={`/authors/${author && author.id}`}
                  >
                    <GatsbyImage image={authorImage} className="authorImage" />
                  </Link>
                )
              }
              <AuthorInfo>
                <AuthorName
                  to={`/authors/${author && author.id}`}
                >
                  {author && author.name}
                </AuthorName>
                <AuthorDescription>
                  {author && author.description}
                </AuthorDescription>
              </AuthorInfo>
            </Author>
            {toc && (
              <TableOfContentsNav>
                <TocButton
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Jump to Section
                  {
                    isDropdownOpen
                      ? <ArrowDropUpIcon sx={sxTocButtonIcon} />
                      : <ArrowDropDownIcon sx={sxTocButtonIcon} />
                  }
                </TocButton>
                <TocItems isDropdownOpen={isDropdownOpen}>
                  <TableOfContents location={location} page={post} />
                </TocItems>
              </TableOfContentsNav>
            )}
          </Sidebar>
          <Main>
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>{description}</p>
            {
              isPreview
                ? <PostContent content={content} />
                : <MDXRenderer>{content}</MDXRenderer>
            }
            {tags && tags.length ? (
              <Tags>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={`${tag}tag${randomId()}`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </Tags>
            ) : null}
            <Navigation>
              {previous && (
                <Link
                  to={previous.fields.slug}
                  rel="prev"
                  className={'navigationLink navigationPrev'}
                >
                  <div className="navigationSubtext">
                    Previous
                  </div>
                  <div
                    className="navigationText"
                  >
                    {previous.frontmatter.title}
                  </div>
                </Link>
              )}
              {next && (
                <Link
                  to={next.fields.slug}
                  rel="next"
                  className={'navigationLink navigationNext'}
                >
                  <div className="navigationSubtext">
                    Next
                  </div>
                  <div
                    className="navigationText"
                  >
                    {next.frontmatter.title}
                  </div>
                </Link>
              )}
            </Navigation>
          </Main>
        </Grid>
      </Section>
    </React.Fragment>
  );
};

BlogPostTemplate.propTypes = {
  body: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  post: PropTypes.shape({
    body: PropTypes.object,
    frontmatter: PropTypes.object,
    tableOfContents: PropTypes.object,
  }),
  pageContext: PropTypes.object,
  location: PropTypes.object,
  isPreview: PropTypes.bool,
  authorImage: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  tags: PropTypes.array,
  tableOfContents: PropTypes.object,
  disableTableOfContents: PropTypes.bool,
  author: PropTypes.object,
  previous: PropTypes.object,
  next: PropTypes.object,
};

const BlogPost = ({ data, pageContext, location }) => {
  const { mdx: post, allFile } = data;
  const authorImage = allFile.edges[0].node.childImageSharp.gatsbyImageData;
  const socialCardImage = `${config.SITE_URL}${post.frontmatter.featuredimage.childImageSharp.fixed.src}`;
  const { body, frontmatter, tableOfContents } = post;
  return (
    <Layout>
      <BlogPostTemplate
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:url" content={location.pathname} />
            <meta property="og:image" content = {socialCardImage} />
            <meta
              property="twitter:image"
              content={socialCardImage}
            />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
        }
        location={location}
        post={post}
        content={body}
        contentComponent={HTMLContent}
        tableOfContents={tableOfContents}
        authorImage={authorImage}
        {...frontmatter}
        {...pageContext}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
    allFile: PropTypes.object,
  }),
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default BlogPost;

export const pageQuery = graphql`query BlogPostByID($id: String!, $authorIdRegex: String!) {
  mdx(id: {eq: $id}) {
    id
    tableOfContents
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      description
      author {
        id
        name
        description
        bio
        twitter
      }
      featuredimage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          fixed(width: 1200, height: 630) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tags
      disableTableOfContents
      tableOfContentsDepth
    }
    body
  }
  allFile(
    filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "authors"}, name: {regex: $authorIdRegex}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
            width: 50
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`;
