import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { styled } from '@mui/material/styles';

const Title = styled('h2')(({ theme }) => ({
  color: `${theme.palette.text.secondary} !important`,
  fontSize: '0.875rem !important',
  textTransform: 'uppercase',
  marginBottom: '1rem !important',
}));

const List = styled('ul')(() => ({
  listStyle: 'none !important',
  margin: '0 !important',
}));

const ListItem = styled('li')(({ theme }) => ({
  marginBottom: `${theme.spacing(2)} !important`,
}));

const ListItemLink = styled(Link)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.secondary,
  '&:hover': {
    paddingBottom: 3,
    borderBottom: `3px solid ${theme.palette.text.secondary}`,
  },
  '& .activeLink': {
    fontWeight: 500,
    color: theme.palette.text.primary,
    paddingBottom: 3,
    borderBottom: `3px solid ${theme.palette.text.primary}`,
  },
}));

const Sublist = styled('ul')(({ theme }) => ({
  listStyle: 'none !important',
  marginLeft: `${theme.spacing(2)} !important`,
  marginTop: `${theme.spacing(2)} !important`,
}));

function isUnderDepthLimit(depth, maxDepth) {
  if (maxDepth === null) {
    // if no maxDepth is passed in, continue to render more items
    return true;
  }
  return depth < maxDepth;
}

// depth and maxDepth are used to figure out how many bullets deep to render in the ToC sidebar, if no
// max depth is set via the tableOfContentsDepth field in the frontmatter, all headings will be rendered
function createItems(items, location, depth, maxDepth) {
  return (
    items
    && items.map((item, index) => (
      <ListItem
        key={location.pathname + (item.url || `${depth}-${index}`)}
      >
        {item.url && (
          <ListItemLink
            getProps={({ href, location }) => (location && location.href && location.href.includes(href)
              ? {
                className: 'activeLink',
              }
              : null)
            }
            to={location.pathname + item.url}
          >
            {item.title}
          </ListItemLink>
        )}
        {item.items && isUnderDepthLimit(depth, maxDepth) && (
          <Sublist>
          {createItems(item.items, location, depth + 1, maxDepth)}
          </Sublist>
        )}
      </ListItem>
    ))
  );
}

function TableOfContents({ page, location }) {
  return page.tableOfContents.items ? (
    <div>
      <Title>
        Table of Contents
      </Title>
      <List>
        {createItems(
          page.tableOfContents.items,
          location,
          1,
          page.frontmatter.tableOfContentsDepth,
        )}
      </List>
    </div>
  ) : null;
}

TableOfContents.propTypes = {
  location: PropTypes.object,
  page: PropTypes.object,
};

export default TableOfContents;
